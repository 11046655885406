import { customElement, html, LitElement, property, internalProperty, TemplateResult, css } from "lit-element";
import { observer } from "./observer"
import { classMap } from "lit-html/directives/class-map"
const uc3mElementStyles = require("./uc3m-element.scss").default;
const uc3mCommonStyles = require("./common-styles.scss").default;

export abstract class UC3MElement extends LitElement{
    static get styles() {
        return [uc3mCommonStyles, uc3mElementStyles];
    }
}

export { customElement, html, property, internalProperty, classMap, TemplateResult, css, observer }