import firebase from 'firebase/app';
import 'firebase/auth';
import { auth as firebaseUiAuth } from 'firebaseui'

import EventEmitter from 'events'

import { gql } from '@apollo/client/core'
import { UNICAGraphQL } from './unica-graphql'

const firebaseConfig = {
  apiKey: "AIzaSyCLUeymi4CFHgh287SSNge-BpsHrcH1oW8",
  authDomain: "uc3m-unica-294219.firebaseapp.com",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

class UNICAAuth extends EventEmitter {
  private firebaseAuthUI;
  private UIContainer?: Element;
  pendingRedirect?: boolean;
  userLoggedIn?: boolean;
  token: string | null = null;
  userDisplayName: string | null = null;
  userEmail: string | null = null;
  userPhotoURL: string | null = null;
  customProperties: any = {};
  firebaseCurrentUser: firebase.User | null = null;

  private firebaseAuthConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (_authResult: any, _redirectUrl: any) {
        // Do something with the returned AuthResult.
        // Return type determines whether we continue the redirect
        // automatically or whether we leave that to developer to handle.
        /*console.log("FIREBASE UI  signInSuccessWithAuthResult");
        console.log("FIREBASE UI: signInSuccessWithAuthResult _authResult" + JSON.stringify(_authResult));
        console.log("FIREBASE UI: signInSuccessWithAuthResult _redirectUrl" + JSON.stringify(_redirectUrl));*/
        return false;
      },
      signInFailure: function (_error: any) {
        // Some unrecoverable error occurred during sign-in.
        // Return a promise when error handling is completed and FirebaseUI
        // will reset, clearing any UI. This commonly occurs for error code
        // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
        // occurs. Check below for more details on this.
        /*console.log("FIREBASE UI  signInFailure");
        console.log("FIREBASE UI: signInFailure error" + JSON.stringify(error));
        console.log(error);*/
        return Promise.reject();
      },
      uiShown: function () {
        // The widget is rendered.
        // Hide the loader.
        //console.log("FIREBASE UI: Shown");
      }
    },
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        clientId: '737509068043-b3svkhvba0tosfgvdgq6lh43kuqsjq7t.apps.googleusercontent.com',// Needed for Google One-tap sign-up
        fullLabel: 'Iniciar sesión con cuenta Google UC3M'
      }
    ],
    // Required to enable one-tap sign-up credential helper.
    //credentialHelper: firebaseUiAuth.CredentialHelper.GOOGLE_YOLO,
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: 'https://www.uc3m.es/inicio/informacion-legal',
    // Privacy policy url/callback.
    privacyPolicyUrl: function () {
      window.location.assign('https://www.uc3m.es/inicio/informacion-legal');
    }
  };

  constructor() {
    super();

    this.firebaseAuthUI = new firebaseUiAuth.AuthUI(firebase.auth());
    this.firebaseAuthUI.disableAutoSignIn();
    this.pendingRedirect = this.firebaseAuthUI.isPendingRedirect();

    firebaseApp.auth().onIdTokenChanged(async (user) => {
      //console.log("*** AUTH onIdTokenChanged");

      let previousEmail = this.userEmail;
      //let previousToken = this.token;
      //let previousStatus = this.userLoggedIn;
      //let previousPendingRedirect = this.pendingRedirect;
      let newEmail = user?.email || null;
      let newStatus = user ? true : false;
      let newPendingRedirect = this.firebaseAuthUI.isPendingRedirect();
      //console.log("onIdTokenChanged Previous email: " + previousEmail);
      // console.log("onIdTokenChanged Previous status: "+previousStatus);
      // console.log("onIdTokenChanged Previous token: "+previousToken);
      //console.log("onIdTokenChanged Previous Pending Redirect: " + previousPendingRedirect);
      //console.log("onIdTokenChanged New email: " + newEmail);
      //console.log("onIdTokenChanged New status: " + newStatus);
      // console.log("onIdTokenChanged New token: "+newToken);
      //console.log("onIdTokenChanged New Pending Redirect: " + newPendingRedirect);

      this.userLoggedIn = newStatus;
      this.userPhotoURL = user?.photoURL || null;
      this.userEmail = newEmail;
      this.userDisplayName = user?.displayName || null;
      this.pendingRedirect = newPendingRedirect;
      this.firebaseCurrentUser = user;

      if (user) {
        //console.log("USER true - query me");
        const queryMe = gql`
        query {
          me {
            groups
          }
        } 
      `;
        this.customProperties = UNICAGraphQL.client.query({
          query: queryMe,
          fetchPolicy: "no-cache"
        }).then(result => {
          this.customProperties = {
            ...this.customProperties,
            ...result.data.me
          }
        }).catch(err => {
          console.log("Error queryme");
          console.log(err);
        }).finally(() => {
          if (!this.pendingRedirect) {
            //console.log("onIdTokenChanged COMPLETED INITIALIZATION");
            this.emit('authInitialized', true);
  
            if (previousEmail != newEmail){
  
              //console.log("onIdTokenChanged AUTH STATE CHANGED");
              this.emit('authStateChanged', user);
            }
          }
        })
      } else {
        this.customProperties = {};

        if (!this.pendingRedirect) {
          //console.log("onIdTokenChanged COMPLETED INITIALIZATION");
          this.emit('authInitialized', true);

          if (previousEmail != newEmail){

            //console.log("onIdTokenChanged AUTH STATE CHANGED");
            this.emit('authStateChanged', user);
          }

        }
      }
    });
  }

  setContainer(container: Element) {
    this.UIContainer = container;
    this.firebaseAuthUI.start(this.UIContainer, this.firebaseAuthConfig);
  }

  public signOut() {
    firebaseApp.auth().signOut();
    this.firebaseAuthUI.start(this.UIContainer, this.firebaseAuthConfig);
  }


}

const unicaAuth = new UNICAAuth();

export default unicaAuth;