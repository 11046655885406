import { gql } from "@apollo/client/core";
import { Router as VaadinRouter, Route as VaadinRoute, Context, Commands } from "@vaadin/router";
import { UNICAGraphQL } from "./unica-graphql";
import EventEmitter from 'events'


export type UNICARouteDBEntry = {
    uid?: string,
    path: string,
    title?: string,
    icon?: string,
    skip_in_breadcrumb?: boolean,
    skip_in_menu?: boolean,
    component?: string,
    view_config?: object,
    redirect?: string,
    url_link?: string,
    children?: UNICARouteDBEntry[]
}

export type UNICARoute = UNICARouteDBEntry & VaadinRoute & {
    animate: Boolean, // Animate is used by Vaadin but is not included in BaseRoute type definition.
    fullPath: string,
    bundle2: string
};

let routeNotFound: UNICARouteDBEntry = {
    path: '(.*)',
    title: "Página no encontrada",
    skip_in_breadcrumb: false,
    skip_in_menu: true,
    component: 'error/view-generic-error',
    view_config: {
        errorName: 'Página no encontrada',
        errorDescription: 'La página solicitada no existe o no dispone de priviligeos para acceder a ella.'
    }
}

let appNotFound: UNICARouteDBEntry = {
    path: '/app-not-found',
    title: "Aplicación no encontrada",
    skip_in_breadcrumb: false,
    skip_in_menu: true,
    component: 'error/view-generic-error',
    view_config: {
        errorName: 'Aplicación no encontrada',
        errorDescription: 'La aplicación a la que intenta acceder no existe o no dispone de priviligeos para la misma.'
    }
}

class _UNICARouter extends EventEmitter {
    vaadinRouter: VaadinRouter = new VaadinRouter();
    app_id: String = "";
    app_config: any = null;

    setOutlet(HTMLElement: Node) {
        this.vaadinRouter.setOutlet(HTMLElement)
    }

    setRoutes(routes: UNICARouteDBEntry[]) {
        let processedRoutes = routes.map((route) => this.processRoute(route));
        this.vaadinRouter.setRoutes(processedRoutes);
        this.emit('routes-changed', this.vaadinRouter.getRoutes());
    }

    getRoutes(): UNICARoute[] {
        return <UNICARoute[]>this.vaadinRouter.getRoutes()
    }

    constructor() {
        super();
        var urlParams = new URLSearchParams(window.location.search);
        this.app_id = urlParams.get('app') || location.hostname;
    }

    initApp() {
        return UNICAGraphQL.client.query({
            query: gql`
            query MyQuery($app_id: String!) {
                unica_app_by_pk(id: $app_id) {
                  header
                  title
                  name
                }
              }
              `,
            variables: {
                app_id: this.app_id
            }
        }).then(result => {
            this.app_config = result.data.unica_app_by_pk;
            //console.log(this.app_config);
            return this.app_config;
        });
    }

    updateRoutes() {
        UNICAGraphQL.client.query({
            query: gql`query MyQuery($app_id: String){
                unica_route(where: {parent_route_uid: {_is_null: true}, app_id: {_eq: $app_id}}, order_by: {path: desc}) {
                  component
                  icon
                  path
                  redirect
                  url_link
                  skip_in_breadcrumb
                  skip_in_menu
                  title
                  uid
                  view_config
                  children {
                    component
                    icon
                    path
                    redirect
                    url_link
                    skip_in_breadcrumb
                    skip_in_menu
                    title
                    uid
                    view_config
                  }
                }
              }`,
            variables: {
                app_id: this.app_id
            },
            fetchPolicy: "no-cache"
        }).then(result => {
            let routesDB = result.data.unica_route;
            routesDB = [...routesDB, appNotFound, routeNotFound];
            this.setRoutes(routesDB);
        });
    }

    setContainer(outlet: HTMLElement) {
        this.setOutlet(outlet);
    }

    private processRoute(route: UNICARouteDBEntry, parentPath: string = ''): UNICARoute {
        let processedRoute: any = {
            ...route,
            animate: true,
            fullPath: parentPath + route.path
        }


        if (route.url_link)
            processedRoute.action = this.routeActionLink;
        else if (route.redirect)
            processedRoute.action = this.routeActionRedirect;
        else if (route.component)
            processedRoute.action = this.routeActionComponent.bind(this);


        if (route.children)
            processedRoute.children = route.children.map((subroute: UNICARouteDBEntry) => this.processRoute(subroute, route.path))
        else
            processedRoute.children = this.getChildren.bind(this)

        return processedRoute;
    }


    private routeActionLink(context: Context, _command: Commands) {
        window.location.href = <string>(<UNICARoute>context.route).url_link;
    }

    private routeActionRedirect(context: Context, command: Commands) {
        let redirect = command.redirect(<string>(<UNICARoute>context.route).redirect);
        //console.log(redirect);
        return redirect;
    }

    private routeActionComponent(context: Context, command: Commands) {
        if (!this.app_config && context.pathname != '/app-not-found') {
            //console.log(this.app_config)
            return command.redirect('/app-not-found')
        }
        else {
            let component = <string>(<UNICARoute>context.route).component;
            let componentPath = component.split('/').slice(0, -1).join('/');
            let componentName = component.split('/').slice(-1)[0];
            import(/* webpackChunkName: "views/[request]" */ `../views/${componentPath}/${componentName}.ts`);
            let webcomponent = command.component(componentName);
            Object.assign(webcomponent, { ...(<UNICARoute>context.route).view_config });
            return webcomponent;
        }
    }

    private getChildren(context: Context) {
        return UNICAGraphQL.client.query({
            query: gql`
            query MyQuery($parent_route_uid:uuid, $app_id: String) {
                unica_route(where: {parent_route_uid: {_eq: $parent_route_uid}, app_id: { _eq: $app_id}}, order_by: {path: desc}) {
                component
                icon
                path
                redirect
                url_link
                skip_in_breadcrumb
                skip_in_menu
                title
                uid
                view_config
                }
            }
            `,
            variables: {
                parent_route_uid: (<UNICARoute>context.route).uid,
                app_id: this.app_id
            }
        }).then(response => {
            context.route.children = response.data.unica_route.map((subroute: UNICARouteDBEntry) => this.processRoute(subroute, context.route.path));
            Promise.resolve(context.route.children);
        })
    }
}

const UNICARouter = new _UNICARouter();
export { UNICARouter };