import { ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import  UNICAAuth  from './unica-auth'

class _UNICAGraphQL {
  // Instantiate required constructor fields
  private cache = new InMemoryCache();
  private hasuraCloudRunURI = 'https://hasura-4oo4hwlvxa-ew.a.run.app/v1/graphql';


  private authMiddleware = setContext(async (_req, { headers }) => {
    let token;
    if (UNICAAuth.firebaseCurrentUser) {
      token = await UNICAAuth.firebaseCurrentUser.getIdToken();
    }

    //console.log(`token: ${token}`);

    let nextHeaders = headers;
    if (token) {
      nextHeaders = {
        ...headers,
        authorization: `Bearer ${token}`
      };
    }

    return {
      headers: nextHeaders,
    };
  });
  
  public client = new ApolloClient({
    // Provide required constructor fields
    cache: this.cache,
    link: from([this.authMiddleware, new HttpLink({uri: this.hasuraCloudRunURI})]),
    connectToDevTools: true,
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
    }
  });


}


const UNICAGraphQL = new _UNICAGraphQL();

declare const window: any;
window.__APOLLO_CLIENT__ = UNICAGraphQL.client;

export { UNICAGraphQL };