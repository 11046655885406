import { customElement, html, LitElement, property } from "lit-element";
import { classMap } from 'lit-html/directives/class-map';
import { MDCRipple } from '@material/ripple';
import { MDCMenu } from '@material/menu';
import { Corner } from '@material/menu-surface';
import '@material/mwc-icon-button';
import '@material/mwc-linear-progress';
import '@material/mwc-snackbar';

import { installMediaQueryWatcher } from 'pwa-helpers/media-query.js';
import { updateMetadata } from 'pwa-helpers/metadata.js';

import './uc3m-navmenu';
import './uc3m-breadcrumb';

//import firebase from "firebase/app";
//import  UC3MAuth from  './unica-auth';
import UNICAAuth from './unica-auth'
import { UNICARouter, UNICARoute } from './unica-router'

const uc3mAppStyle = require("./uc3m-app.scss").default;
const uc3mCommonStyles = require("./common-styles.scss").default;
const uc3mTheme = require("./uc3m-theme-light.scss").default;


@customElement("uc3m-app")
export class UC3MAppUI extends LitElement {

    @property({ type: String }) pageMetaTitlePrefix?: string;
    @property({ type: String }) appName: string = "";
    @property({ type: String }) appHeader: string = "";
    @property({ type: String }) appVersion: string = "";
    @property({ type: String }) language: string = "es";

    @property({ type: String }) routes: UNICARoute[] = [];

    @property({ type: Object }) menuUser?: MDCMenu;
    @property({ type: Object }) menuAuth?: MDCMenu;

    @property({ type: Boolean, reflect: true }) screenNarrow: boolean = false;
    @property({ type: Boolean }) drawerHamburgerVisible: boolean = false;
    @property({ type: Boolean }) drawerOpen: boolean = false;
    @property({ type: Boolean }) blockedLoading: boolean = true;

    @property({ type: String }) userLoggedIn: boolean = false;
    @property({ type: String }) userDisplayName?: string;
    @property({ type: String }) userEmail?: string;
    @property({ type: String }) userPhotoURL?: string;

    static get styles() {
        return [uc3mCommonStyles, uc3mAppStyle];
    }

    constructor() {
        super();

        // Set theme as style in HTML Document Head
        var sheet = document.createElement('style');
        sheet.innerHTML = uc3mTheme.cssText;
        document.head.appendChild(sheet); // append in head


        installMediaQueryWatcher(`(min-width: 600px)`, (matches) => {
            this.screenNarrow = !matches;
        });



        UNICARouter.on('routes-changed', (routes) => {
            //console.log(routes);
            this.routes = routes;
            this.drawerHamburgerVisible = routes.filter( route => !route.skip_in_menu).length;
            if(!this.drawerHamburgerVisible)
                this.drawerOpen = false;
        })

        let pendingBlockingInitSteps = 2;

        UNICAAuth.on('authStateChanged', (user) => {
            this.userLoggedIn = user ? true : false;
            this.userPhotoURL = user?.photoURL || undefined;
            this.userEmail = user?.email || undefined;
            this.userDisplayName = user?.displayName || undefined;
            UNICARouter.updateRoutes();
        })

        UNICAAuth.on('authInitialized', () => {
            pendingBlockingInitSteps--;
            if (!pendingBlockingInitSteps)
                this.blockedLoading = false;
        })

        UNICARouter.initApp().then((appConfig) => {
            if (appConfig) {
                this.appName = appConfig.name;
                this.pageMetaTitlePrefix = appConfig.title;
                this.appHeader = appConfig.header;
            }
            UNICARouter.updateRoutes();
            
            pendingBlockingInitSteps--;
            if (!pendingBlockingInitSteps)
                this.blockedLoading = false;
        });

        window.addEventListener('vaadin-router-location-changed', (event: any) => {
            if(this.drawerOpen && this.screenNarrow)
                this.drawerOpen = false;
            updateMetadata({
                title: (this.pageMetaTitlePrefix || '') + ((this.pageMetaTitlePrefix && event.detail.location.route.title) ? " - " : '') + (event.detail.location.route.title || ''),
                description: event.detail.location.route.description?.es || '',
                url: window.location.href
            });
        })

    }


    firstUpdated() {
        UNICAAuth.setContainer(<Element>(this.shadowRoot?.getElementById("firebaseui-auth-container")));
        UNICARouter.setContainer(<HTMLElement>this.shadowRoot?.getElementById('main-content'));

        /*** Init MDC Components */
        // MDC Menu User
        this.menuUser = new MDCMenu(<Element>this.shadowRoot?.getElementById('menu-user'));
        this.menuUser.setAnchorElement(<Element>this.shadowRoot?.getElementById('btn-menu-user'));
        this.menuUser.setAnchorCorner(Corner.BOTTOM_RIGHT);

        this.menuAuth = new MDCMenu(<Element>this.shadowRoot?.getElementById('menu-auth'));
        this.menuAuth.setAnchorElement(<Element>this.shadowRoot?.getElementById('btn-menu-auth'));
        this.menuAuth.setAnchorCorner(Corner.BOTTOM_RIGHT);

        // Any MDC Icon Button Ripple
        [].map.call(this.shadowRoot?.querySelectorAll('.mdc-icon-button'), function (el) {
            const iconButtonRipple = new MDCRipple(el);
            iconButtonRipple.unbounded = true;
        });


    }

    render() {
        return html`
        <link type="text/css" rel="stylesheet" href="https://www.gstatic.com/firebasejs/ui/4.7.0/firebase-ui-auth.css" />
        
        <body class="mdc-typography">
            <!-- HEADER ROW 1-->
            <header id="header1">
                <section class="left">
                    <button class="material-icons mdc-top-app-bar__navigation-icon mdc-icon-button"
                        ?hidden=${!this.drawerHamburgerVisible}
                        @click=${this.toggleDrawer}>menu</button>
                    <a class="nolink" id="headerLink" href="/">
                            <svg id="uc3m-logo" xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision"
                                viewBox="0 0 88.67 26.81">
                                <title>UC3M</title>
                                <path
                                    d="M33.39,13.92a6,6,0,0,0-4.2-1.7,4.87,4.87,0,1,0,0,9.74,6,6,0,0,0,4.21-1.72v5.61a9.71,9.71,0,1,1,0-17.52Z"
                                    class="fill-uc3m"></path>
                                <path d="M12.14,7.32v11a3.64,3.64,0,0,1-7.28,0v-11H0v11a8.51,8.51,0,1,0,17,0v-11Z"
                                    class="fill-uc3m"></path>
                                <path
                                    d="M45.57,4.91l-2.81,7.31h3.33a4.87,4.87,0,1,1-4.87,4.86H36.38A9.71,9.71,0,1,0,49.55,8l3.1-8-15,0V4.91Z"
                                    class="fill-uc3m"></path>
                                <path
                                    d="M74.68,10.15A7.87,7.87,0,0,0,64.34,8.61l0-1.27H59.51V26.79h4.85l0-11a3.63,3.63,0,1,1,7.26,0V26.79h4.88v-11a3.64,3.64,0,1,1,7.27,0v11h4.87l0-11.58a7.93,7.93,0,0,0-14-5.06Z"
                                    class="fill-uc3m"></path>
                            </svg>
                        <span id="app-name" ?hidden=${!this.appHeader}>${this.appHeader}</span>
                    </a>
                </section>
                <section class="center"></section>
                <section class="right" role="toolbar">
                    <button id="btn-menu-user" class="mdc-icon-button" @click=${() => { this.toggleMenu(this.menuUser); }}
                        ?hidden=${!this.userLoggedIn} >
                        <img src=${this.userPhotoURL} class="uc3m-avatar" />
                    </button>
                    <mwc-icon-button id="btn-menu-auth" ?hidden=${this.userLoggedIn} icon="person" @click=${() => { this.toggleMenu(this.menuAuth) }}></mwc-icon-button>
                </section>
            </header>
            <div id="header2">
                <uc3m-breadcrumb></uc3m-breadcrumb>
            </div>
        
            <div id="scrim" ?hidden=${!(this.drawerOpen && this.screenNarrow)}></div>
        
            <!-- DRAWER -->
            <aside id="drawer" class=${classMap({ 'open': this.drawerOpen, 'modal': this.screenNarrow })}>
                <uc3m-navmenu id="navmenu" .routes=${this.routes}></uc3m-navmenu>
                <footer id="footer">
                    ${this.appName} v.${this.appVersion}<br />
                    <div>ÚNICA</div>
                    Universidad Carlos III de Madrid<br />
                    Servicio de Informática y Comunicaciones
                </footer>
            </aside>
        
            <div id="main-content" ?blockedLoadingIndeterminate=${this.blockedLoading}>
            </div>
        
            <!-- FLOATING MENUS -->
            <!-- USER MENU -->
            <div class="mdc-menu mdc-menu-surface" id="menu-user">
                <div class="mdc-list" role="menu" aria-hidden="true" aria-orientation="vertical" tabindex="-1">
                    <div class="mdc-list-item " role="menuitem">
                        <span class="mdc-list-item__ripple"></span>
                        <i class="material-icons mdc-list-item__graphic" aria-hidden="true">person</i>
                        <span class="mdc-list-item__text">
                            <span class="mdc-list-item__primary-text">${this.userDisplayName}</span>
                            <span class="mdc-list-item__secondary-text">${this.userEmail}</span>
                        </span>
                    </div>
                    <div role="separator" class="mdc-list-divider"></div>
                    <div class="mdc-list-item" role="menuitem" @click="${() => { UNICAAuth.signOut() }}">
                        <span class="mdc-list-item__ripple"></span>
                        <i class="material-icons mdc-list-item__graphic" aria-hidden="true">exit_to_app</i>
                        <span class="mdc-list-item__text">Cerrar sesión</span>
                    </div>
                </div>
            </div>
            <!-- AUTH MENU -->
            <div class="mdc-menu mdc-menu-surface" id="menu-auth">
                <div id="firebaseui-auth-container"></div>
            </div>
              
        </body>
        `
    }

    toggleDrawer() {
        this.drawerOpen = !this.drawerOpen;
    }

    toggleMenu(menu?: MDCMenu) {
        if (menu)
            menu.open = !menu.open;
    }

}