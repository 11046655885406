import { UC3MElement, customElement, property, html, TemplateResult } from "./uc3m-element";

const styles = require("./uc3m-breadcrumb.scss").default;

@customElement("uc3m-breadcrumb")
export class UC3MBreadcrumb extends UC3MElement {

    @property({ type: Array }) currentRoutes: any[] = [];
    @property({ type: Array }) currentRoutesParameters: Object = {};

    static get styles() {
        return [styles];
    }

    constructor() {
        super();

        window.addEventListener('vaadin-router-location-changed', (event: any) => {
            this.currentRoutes = event.detail.location.routes;
            this.currentRoutesParameters = event.detail.location.params;
        });
    }

    render() {
        return html`
            ${this.currentRoutes?.map( route => route.skip_in_breadcrumb && this.currentRoutes?.length > 1 ? '' : this.renderBreadcrumbLevel(route))}
        `
    }

    renderBreadcrumbLevel(route: any): TemplateResult {
        let text = route.title;
        if(route.title && route.title.includes(":")){
            let param = route.title.substring(1);
            console.log(this.currentRoutesParameters);
            let value = Object.entries(this.currentRoutesParameters).filter((item) => { return item[0]===param})[0][1];
            console.log(param);
            console.log(value);
            text = value;
        }
        return html`
            <a href="${route.fullPath}">${text}</a>
            <span class="separator"> > </span>
        `
    }
}
