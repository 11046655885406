import { UC3MElement, customElement, html, property, TemplateResult } from "./uc3m-element";
import { UNICARoute, UNICARouter } from './unica-router'

const styles = require("./uc3m-navmenu.scss").default;

@customElement("uc3m-navmenu")
export class UC3MNavmenu extends UC3MElement {

    @property({ type: Array }) activeRoutePathUIDs: string[] = [];

    static get styles() {
        return [...super.styles, styles];
    }

    constructor() {
        super();
        window.addEventListener('vaadin-router-location-changed', (event: any) => {
            this.activeRoutePathUIDs = event.detail.location.routes.map((route: UNICARoute) => route.uid);
        });
    }

    render() {
        return html`
            <nav class="mdc-list mdc-list--icon-list" id="drawer-navmenu">
                ${UNICARouter.getRoutes().map((route:UNICARoute) => html`${this.renderNavmenuItem(route)}`)}
            </nav>
        `
    }

    toggleNavmenuSection(e: MouseEvent) {
        e.cancelBubble = true;
        e.preventDefault();
        let a = (e.currentTarget as HTMLElement);
        a.parentElement?.parentElement?.toggleAttribute('collapsed');
    }

    renderNavmenuItem(route: UNICARoute, parentPath?: string): TemplateResult {
        if(route.skip_in_menu)
            return html``

        let childrenRoutes = route.children;
        if(typeof childrenRoutes === "function")
            childrenRoutes = undefined;

        return route.url_link ? html`
        <div collapsed>
            <a class="mdc-list-item" tabindex="0" href=${ route.url_link} target='_blank'>
                <span class="mdc-list-item__ripple"></span>
                <i class="material-icons mdc-list-item__graphic" aria-hidden="true">${route.icon}</i>
                <span class="mdc-list-item__text">${route.title}</span>
                <i class="material-icons mdc-list-item__meta" aria-hidden="true"></i>
                ${ (!childrenRoutes?.length || !childrenRoutes.filter((route) => !route.skip_in_menu).length) ? html`` : html`
                <i class="mdc-list-item__meta material-icons mdc-icon-button btn-expand" aria-label="Expandir sección"
                    @click=${this.toggleNavmenuSection}>arrow_drop_down</i>
                <i class="mdc-list-item__meta material-icons mdc-icon-button btn-collapse" aria-label="Contraer sección"
                    @click=${this.toggleNavmenuSection}>arrow_drop_up</i>
                `}
            </a>
            ${childrenRoutes?.map((childRoute:any) => html`
                ${this.renderNavmenuItem(<any>childRoute,route.path)}
            `)}
        </div>
        ` : html`
        <div collapsed>
            <a class="mdc-list-item" tabindex="0" href=${ (parentPath ?? '') + route.path}>
                <span class="mdc-list-item__ripple"></span>
                <i class="material-icons mdc-list-item__graphic" aria-hidden="true">${route.icon}</i>
                <span class="mdc-list-item__text">${route.title}</span>
                <i class="material-icons mdc-list-item__meta" aria-hidden="true"></i>
                ${ (!childrenRoutes?.length || !childrenRoutes.filter((route) => !route.skip_in_menu).length) ? html`` : html`
                <i class="mdc-list-item__meta material-icons mdc-icon-button btn-expand" aria-label="Expandir sección"
                    @click=${this.toggleNavmenuSection}>arrow_drop_down</i>
                <i class="mdc-list-item__meta material-icons mdc-icon-button btn-collapse" aria-label="Contraer sección"
                    @click=${this.toggleNavmenuSection}>arrow_drop_up</i>
                `}
            </a>
            ${childrenRoutes?.map((childRoute:any) => html`
                ${this.renderNavmenuItem(<any>childRoute,route.path)}
            `)}
        </div>
        ` 
    }

}
